<template>
  <div>
    <form @submit.prevent="handleFormSubmit()">
      <div class="row align-items-end">
        <!-- <div class="col-3 my-3">
          <label for="">Catégorie</label>
          <select v-model="section_reference" class="form-select">
            <option
              v-for="(section, index) in sections"
              :key="index++"
              :value="section.reference"
            >
              {{ section.fullName }}
            </option>
          </select>
          <div class="invalid-feedback">More example invalid feedback text</div>
        </div> -->

        <div class="col-xl-3 col-12 my-3">
          <label for="itemCodeInput"> Code </label>
          <input
            v-model="itemCode"
            @input="onItemCodeChangeSelected(itemCode)"
            list="itemCodeList"
            class="form-control"
            id="itemCodeInput"
            :disabled="disabledInput"
          />
          <datalist id="itemCodeList">
            <option
              v-for="(item, index) in filteredItems"
              :key="index"
              :value="item.code"
            >
              {{ item.code }}
            </option>
          </datalist>
        </div>

        <div class="col-xl-4 col-12 my-3">
          <label for="itemInput">Produit</label>
          <input
            v-model="item_reference"
            @input="onItemChangeSelected(item_reference)"
            list="itemList"
            class="form-control"
            id="itemInput"
            :disabled="disabledInput"
          />
          <datalist id="itemList">
            <option
              v-for="(item, index) in filteredItems"
              :key="index"
              :value="item.reference"
            >
              {{ item.fullName }}
            </option>
          </datalist>
        </div>

        <div class="col-xl-5 col-12 my-3">
          <label for="">Produit</label>
          <input :value="selectedItem.fullName" disabled class="form-control" />
        </div>

        <div class="col-xl-2 col-12 my-3">
          <label for=""> TVA </label>
          <input
            :value="selectedItem.tva + ' %'"
            disabled
            class="form-control"
          />
        </div>

        <div class="col-xl-2 col-12 my-3">
          <label for=""> Prix d'achat TTC </label>
          <input
            v-if="Object.keys(filteredStocks).length == 1"
            :value="filteredStocks[0].purchasePriceTTC + ' DIRHAMS'"
            disabled
            class="form-control"
          />
          <input v-else :value="' '" disabled class="form-control" />
        </div>

        <div class="col-xl-3 col-12 my-3">
          <label for=""> Stock <span class="text-danger">*</span> </label>
          <select
            v-if="Object.keys(filteredStocks).length > 1"
            v-model="saleItem.stock_reference"
            class="form-select"
          >
            <option
              v-for="(stock, index) in filteredStocks"
              :key="index"
              :value="stock.reference"
            >
              {{ stock.fullName }} Quantité : {{ stock.quantity }}
              {{ stock.measuring }}
            </option>
          </select>
          <input
            v-else-if="Object.keys(filteredStocks).length == 1"
            type="text"
            disabled
            :value="
              filteredStocks[0].fullName +
              ' ' +
              filteredStocks[0].quantity +
              ' : ' +
              filteredStocks[0].measuring
            "
            class="form-control"
          />

          <input
            v-else-if="
              Object.keys(filteredStocks).length == 0 &&
              Object.keys(selectedItem) != ''
            "
            type="text"
            disabled
            :value="'Stock insuffisant'"
            class="form-control bg-danger text-white"
          />

          <input
            v-else
            type="text"
            disabled
            :value="' '"
            class="form-control"
          />
        </div>

        <div class="col my-3">
          <label for="">Quantité</label>
          <input v-model="saleItem.quantity" class="form-control" required />
        </div>
        <div class="col my-3">
          <label for="">Prix</label>
          <input v-model="saleItem.priceTTC" class="form-control" />
        </div>
      </div>
      <br />
      <div class="row justify-content-end">
        <div class="col-auto my-3">
          <button
            type="button"
            class="btn btn-warning d-flex align-items-center"
            @click="empty()"
          >
            <i class="bi bi-arrow-clockwise fs-4 me-2"></i>
            <span> Vider </span>
          </button>
        </div>

        <div class="col-auto my-3">
          <button
            class="btn btn-primary"
            type="submit"
            @keyup.enter="handleFormSubmit()"
          >
            <i class="bi bi-plus-circle"></i> Enregistrer
          </button>
        </div>

        <div class="col-auto my-3">
          <button
            type="button"
            class="btn btn-secondary d-flex align-items-center"
            @click="refresh()"
          >
            <i class="bi bi-arrow-clockwise fs-4 me-2"></i>
            <span> Actualiser </span>
          </button>
        </div>
      </div>
    </form>
    <br />
    <table class="table table-striped table-hover">
      <thead>
        <tr>
          <th scope="col">Nº</th>
          <th scope="col">Désignation</th>
          <th scope="col">Prix (DH*)</th>
          <th scope="col">Quantité</th>
          <th scope="col">Montant Total (DH*)</th>
          <th scope="col">Stock</th>
          <th scope="col">Remarque</th>
          <th scope="col"></th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(item, index) in sale.sale_items" :key="index++">
          <td>
            {{ index }}
          </td>

          <td>
            <span v-if="item.item" class="text-uppercase">
              {{ item.item.fullName }}
            </span>
          </td>
          <td class="">
            <span v-if="item.priceTTC != null">
              {{ item.priceTTC }}
            </span>
          </td>
          <td class="">
            <span v-if="item.quantity != null">
              {{ item.quantity }}
            </span>
            <span v-if="item.item">
              {{ item.item.measuring }}
            </span>
          </td>
          <td class="">
            <span v-if="item.totalPriceTTC != null">
              {{ item.totalPriceTTC }}
            </span>
          </td>

          <td>
            <span v-if="item.stock">
              {{ item.stock.fullName }}
            </span>
          </td>

          <td>
            {{ item.remark }}
          </td>

          <td class="d-flex">
            <button @click="remove(item)" class="btn text-danger">
              <i class="bi bi-trash-fill"></i>
            </button>
            <button @click="editItem(item)" class="btn text-secondary">
              <i class="bi bi-pencil-square"></i>
            </button>
          </td>
        </tr>
      </tbody>
    </table>
    <div class="row justify-content-between">
      <div class="col text-center">
        <div class="w-auto">
          <span class="text-white bg-secondary rounded px-3 py-1">
            {{ sale.totalQuantity }}
          </span>
        </div>
      </div>

      <div class="col text-center">
        <div class="w-auto">
          <span class="text-white bg-primary rounded px-3 py-1">
            {{ sale.totalPriceTTC }}
          </span>
        </div>
      </div>

      <div class="col text-center">
        <div class="w-auto">
          <span class="text-white bg-success rounded px-3 py-1">
            {{ sale.amountPaid }}
          </span>
        </div>
      </div>

      <div class="col text-center">
        <div class="w-auto">
          <span class="text-white bg-danger rounded px-3 py-1">
            {{ sale.amountUnpaid }}
          </span>
        </div>
      </div>
    </div>
    <br />
    <div class="row mt-3 justify-content-between align-items-center">
      <div class="col-8">
        <div class="form-check align-items-center">
          <input
            type="checkbox"
            id="accept"
            value=""
            :checked="accept ? true : false"
            class="form-check-input me-2"
            @click="accept ? (accept = false) : (accept = true)"
          />
          <label class="form-check-label" for="accept">
            J'accepte que les informations relatives à l'achat ( Quantité totale
            : <strong>{{ totalQuantityItems }}</strong> Kg, Mantant totale :
            <strong>
              {{ totalPriceTTCItems }}
            </strong>
            DH, Nombre d'articles :

            <strong>{{ numberItems }}</strong> ) et correctes.
            <span class="text-danger">*</span>
          </label>
        </div>
      </div>
      <div class="col-auto">
        <button
          class="btn btn-primary"
          @click="next(sale.reference)"
          :disabled="!accept ? true : false"
          v-if="accept"
        >
          <i class="bi bi-arrow-right-circle"></i> Suivant
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      saleItem: {},
      section_reference: null,
      item_reference: null,
      itemCode: null,
      accept: false,
      customer_change: true,
      showError: false,
      errorFields: [],
      selectedItem: {},
      disabledInput:false,
    };
  },
  computed: {
    ...mapGetters("item", {
      items: "getAll",
    }),
    ...mapGetters("section", {
      sections: "getAll",
    }),
    ...mapGetters("customer", {
      customers: "getAll",
    }),
    ...mapGetters("sale", {
      saleItems: "getItems",
      totalQuantityItems: "totalQuantityItems",
      totalPriceTTCItems: "totalPriceTTCItems",
      numberItems: "numberItems",
      sale: "getSale",
    }),
    ...mapGetters("stock", {
      stocks: "getStocks",
    }),

    filteredStocks() {
      if (this.saleItem.quantity < 0)
        return this.stocks.filter(
          (stock) => stock.item_reference === this.selectedItem.reference
        );

      return this.stocks.filter(
        (stock) =>
          stock.item_reference === this.selectedItem.reference &&
          stock.quantity > 0
      );
    },

    filteredItems() {
      if (this.section_reference == null) {
        return this.items;
      }

      return this.items.filter(
        (item) => item.section_reference == this.section_reference
      );
    },
  },
  methods: {
    async handleFormSubmit() {
      if (this.saleItem.id != null) await this.update();
      else await this.save();
    },
    async empty() {
      this.saleItem = {};
      this.section_reference = null;
      this.item_reference = null;
      this.itemCode = null;
      this.accept = false;
      this.selectedItem = {};
    },

    async editItem(item) {
      await (this.item_reference = item.item_reference);
      await (this.selectedItem = this.items.find(
        (p) => p.reference == this.item_reference
      ));
      // return

      await (this.saleItem.item_reference = this.selectedItem.reference);
      await (this.saleItem.sale_reference = this.sale.reference);
      await (this.saleItem.id = item.id);
      await (this.saleItem.quantity = item.quantity);
      await (this.saleItem.quantity = item.quantity);

      if (this.selectedItem != null) {
        await (this.item_reference = "");
      }
      await (this.disabledInput = true);
      if (Object.keys(this.filteredStocks).length == 1) {
        await (this.saleItem.stock_reference =
          this.filteredStocks[0].reference);
      }
      await (this.section_reference = "");
    },

    async update() {
      console.log(this.saleItem);
      let quantity = parseFloat(this.saleItem.quantity);
      let id = this.saleItem.id;
      let sale_reference = this.saleItem.sale_reference;
      let priceTTC = parseFloat(this.saleItem.priceTTC);
      let item_reference = this.saleItem.item_reference;
      let stock_reference = this.saleItem.stock_reference;

      await this.$confirm({
        message:
          "Vous voulez modifier l'article  " + this.selectedItem.fullName,
        button: {
          no: "Non",
          yes: "Oui",
        },

        callback: (confirm) => {
          if (confirm) {
            this.$store
              .dispatch("sale/updateItem", {
                id,
                quantity,
                sale_reference,
                priceTTC,
                item_reference,
                stock_reference,
              })
              .then(() => {
                // Call the refresh function after the remove action is completed
                this.refresh();
              });
            this.empty();
          }
        },
      });
      await (this.disabledInput = false);
    },

    async save() {
      await this.$store.dispatch("sale/storeItem", this.saleItem);
      await (this.saleItem.item_reference = "");
      await (this.saleItem.stock_reference = "");
      await (this.saleItem.quantity = "");
      await (this.saleItem.priceTTC = "");
      await (this.section_reference = null);
      await (this.item_reference = null);
      await (this.selectedItem = {});
      await this.refresh();
       await (this.disabledInput = false);
    },

    async remove(data) {
      let item = data.item;
      await this.$confirm({
        message: "Vous voulez supprimer l'article  " + item.fullName,
        button: {
          no: "Non",
          yes: "Oui",
        },

        callback: (confirm) => {
          if (confirm) {
            this.$store.dispatch("sale/destroyItem", data.id);
          }
        },
      });
    },
    async refresh() {
      await this.$store.dispatch("stock/getAll");
      await this.$store.dispatch("sale/show", this.$route.params.reference);
      await this.$store.dispatch("section/getAll");
      await this.$store.dispatch("item/getAll");
      await this.$store.dispatch("customer/getAll");
    },
    async next(reference) {
      let sale_reference = reference;
      this.$router.push({
        name: "sales-edit",
        query: { reference: sale_reference },
      });
    },

    async onItemChangeSelected() {
      await (this.saleItem = { priceTTC: 0.0, quantity: 1 });

      await (this.selectedItem = this.items.find(
        (p) => p.reference == this.item_reference
      ));

      await (this.saleItem.item_reference = this.selectedItem.reference);
      await (this.saleItem.sale_reference = this.sale.reference);

      if (this.selectedItem != null) {
        await (this.item_reference = "");
      }
      if (Object.keys(this.filteredStocks).length == 1) {
        await (this.saleItem.stock_reference =
          this.filteredStocks[0].reference);
      }
    },

    async onItemCodeChangeSelected() {
      await (this.saleItem = { priceTTC: 0.0, quantity: 1 });

      await (this.selectedItem = this.items.find(
        (p) => p.code == this.itemCode
      ));

      await (this.saleItem.item_reference = this.selectedItem.reference);
      await (this.saleItem.sale_reference = this.sale.reference);

      if (this.selectedItem != null) {
        await (this.itemCode = "");
      }
      if (Object.keys(this.filteredStocks).length == 1) {
        await (this.saleItem.stock_reference =
          this.filteredStocks[0].reference);
      }
    },
  },
  async beforeMount() {
    await this.$store.dispatch("stock/getAll");
    await this.$store.dispatch("sale/show", this.$route.params.reference);
    await this.$store.dispatch("section/getAll");
    await this.$store.dispatch("item/getAll");
    await this.$store.dispatch("customer/getAll");
  },
};
</script>
